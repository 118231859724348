//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import ViralForm from '@components/ViralForm.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'ViralModal',

        components: {
            ViralForm,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'viral',
            };
        },
        i18nOptions: { namespaces: ['modal_content'] },
    };
