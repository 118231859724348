
/* This is client-side code. */
/* eslint-env browser */

const
    { isFilled } = require('rocketship-validator'),
    base = require('./validators');

module.exports = {
    register: { ...base.register, 'g-recaptcha-response': { requireCaptcha } },
    applicantRegister: { ...base.applicantRegister, 'g-recaptcha-response': { requireCaptcha } },
    login: base.login,
    ownerLogin: base.ownerLogin,
    viral: base.viral,
    faqContact: base.faqContact,
};

function requireCaptcha (value, field, profile) {
    // Short-circuit where reCaptcha can't work anyway.
    if (
        // Don't require captcha on the client-side in test mode.
        profile.isAutomatedTest ||
        // Don't require captcha client-side on `localhost`.
        // (running locally, likely in Docker)
        window.location.hostname === 'localhost'
    ) {
        return true;
    }

    if (!isFilled(value)) {
        // Error out early if we don't have a response.
        this.addError(field, 'NULL');
        return false;
    }
    return true;
}
