
import { __utmSetVar, urchinTracker, __utmTrackEvent } from '../../vendor/urchin';

import store from '../store';

export default {
    install (Vue, options) {
        Vue.directive('track', UrchinTrackingDirective);

        Vue.prototype.$urchin = {
            getUrchinPageName,
            trackPageView,
            trackEvent,
        };
    },
};

const UrchinTrackingDirective = {
    bind (el, binding, vNode, oldVnode) {
        el.addEventListener('click', () => {
            // throw an error if first two required values are NOT specified
            if (!binding.value[0] || !binding.value[1]) {
                console.warn('UrchinTrackingDirective: required event tracking arguments not specified');
                return;
            }
            try {
                trackEvent(...binding.value);
            }
            catch (err) {
                console.warn(`UrchinTrackingDirective error: ${err}`);
            }
        });
    },
};

export function getUrchinPageName () {
    const
        { deployPath } = store.state.app,
        { pageName } = store.state.ui;
        // ! Page MUST be defined as /deploy/path/page
    const urchinPageName = `/${deployPath}/${pageName}`;
    // ! Page names have a limit of 50 characters including the deploy path of the promotion.
    if (urchinPageName.length >= 50) {
        const documentationLink = process.env.NODE_ENV !== 'production' ?
            'Refer to: https://confluence.helloworld.com/display/IT/Urchin+Data+Analytics+Tracking#UrchinDataAnalyticsTracking-HowdoItrackmyevent?' :
            '';
        console.warn(
            `Urchin error: page name is greater than 50 characters. ${documentationLink}`,
        );
    }
    return urchinPageName;
}

export function trackPageView (callback) {
    const
        loggedIn = store.getters['profile/loggedIn'],
        { channel } = store.state.app,
        { affiliateId } = store.state.app;
    let page;
    let userData; // information about the user

    try {
        page = getUrchinPageName();
        // r = registered (indicates if user already registered), c = channel,
        // d = device, a = affiliate
        userData = 'r=' + (loggedIn ? '1' : '0') + ';c=' + channel +
                ';d=def' + ';a=' + affiliateId;

        // __utmSetVar tells the Urchin library what the current state of our application is
        // urchinTracker then fires that data off to the server alongside the current page name
        __utmSetVar(userData);
        urchinTracker(page, undefined, callback);
    }
    catch (err) {
        console.warn(`Urchin.trackPageView error: ${err}`);
        if (typeof callback === 'function') callback();
    }
}

/**
 * The usage of these parameters are open-ended.
 * The usage of these parameters should be defined by your tracking requirements
 * @param {string} category - Typically, the 'component' that was interacted with. Examples: catalog|footer|navigation|viral
 * @param {string} action - Typically, the thing the user did to generate the event. Examples: submit|share|click|exit|navigate
 * @param {string} [label=''] - Typically, the copy that was on the element that was clicked. Examples: submit|go now|click to win
 * @param {string} [value=''] - If there is some relevant data that needs to be passed, use this parameter. Example: userId|emailAddress, etc.
 * @param {Function} callback - callback function
 */
export function trackEvent (category, action, label, value, callback) {
    let page;

    try {
        page = getUrchinPageName();
        __utmTrackEvent(category, action, (label || ''), (value || ''), page, callback);
    }
    catch (err) {
        console.warn(`Urchin.trackEvent error: ${err}`);
        // allow callback to fire even if tracking function had a problem to ensure UX doesn't stop @ the error
        if (typeof callback === 'function') callback();
    }
}
