/* These are turned into rocketship-validation objects.
 * Information on that is located in the readme for that.
 * https://bitbucket.helloworld.com/projects/ROC/repos/validator/browse
 */
const
    range = require('lodash/range'),
    isEmail = require('validator/lib/isEmail'),
    config = require('rocketship-config'),
    { maxFields, displayCAConfirm } = {"_public":true,"maxFields":1,"displayCAConfirm":true},
    // This is used to protect downstream systems with databases that can't handle
    // characters wider than three bytes, like FE2 and HW Analytics.
    // https://jiradc.helloworld.com/browse/SCU-144
    no4ByteChars = /^[\u{000000}-\u{00FFFF}]*$/u,
    noEmail = /^((?!@).)*$/,
    noAlpha = /^(?=^.{12}$)[A-Za-z]{2,2}\d*$|^[0-9]{11,11}$/,
    noAlphaCharLimit = /^[0-9]{8,8}$/,
    charLimitSix = /^[0-9]{6,6}$/,
    ineligibleDomains = /(@wyn.com|@travelandleisure.com|@eprize.com|@eprize.net|@helloworld.com|@merkleinc.com)/;

module.exports = {
    login: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
    },
    ownerLogin: {
        state: { required: true },
    },
    register: {
        first_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        last_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            checkEmail: function _checkEmail (value, key, obj, opts) {
                const validation = this;
                if ((
                    /(@wyn.com|@travelandleisure.com)/
                ).test(value)) {
                    validation.addError(key, 'ineligible_domain');
                    return false;
                }

                return true;
            },
        },
        member_id: {
            required: true,
            no4ByteChars,
            noEmail,
            noAlpha,
        },
        brand: {
            required: true,
        },
        state: {
            required: true,
        },
    },
    applicantRegister: {
        first_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        last_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            checkEmail: function _checkEmail (value, key, obj, opts) {
                const validation = this;
                if ((ineligibleDomains).test(value)) {
                    validation.addError(key, 'ineligible_domain');
                    return false;
                }

                return true;
            },
        },
        address: { required: true },
        city: { required: true },
        state: { required: true },
        zip: {
            required: true,
            isZip: true,
            no4ByteChars,
        },
        age: { required: true },
        income: {
            required: true,
        },
        phone_number: {
            required: true,
            isPhone: true,
        },
        referral_code: { required: true },
        marital_status: { required: true },
    },
    prizeSelection: {
        actionId: {
            required: true,
        },
        prizeKey: {
            required: true,
        },
    },
    viral: {
        to_email1: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            // These validators run on the first field, but are smart enough to check
            // for errors in all TO email addresses.
            // Note: `referringSelf` may only be validated client-side if `email` is a
            // public field in the profile config, and it's not by default.
            referringSelf (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj);

                const referredSelf = toEmailFields.find(([key, email]) => email === obj.selfEmail);

                if (referredSelf) {
                    const [selfKey] = referredSelf;
                    validation.addError(selfKey, 'REFERRED_SELF');
                    return false;
                }

                return true;
            },
            duplicateReferral (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj),
                    seen = {};

                const duplicate = toEmailFields.find(([key, email]) => {
                    if (email in seen) return true;
                    seen[email] = true;
                    return false;
                });

                if (duplicate) {
                    const [duplicateKey] = duplicate;
                    validation.addError(duplicateKey, 'DUPLICATE');
                    return false;
                }

                return true;
            },
        },
        to_name1: {
            required: true,
            no4ByteChars,
        },
    },
    faqContact: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
    },
};

const viralGuards = module.exports.viral;

if (displayCAConfirm) {
    viralGuards.taf_confirm = { required: true, isChecked: true };
}

range(1, maxFields + 1).forEach((fieldNum) => {
    viralGuards['to_name' + fieldNum] = {
        ...viralGuards['to_name' + fieldNum],

        requiresField: 'to_email' + fieldNum,
    };
    viralGuards['to_email' + fieldNum] = {
        ...viralGuards['to_email' + fieldNum],

        requiresField: 'to_name' + fieldNum,
        external: [isEmail],
    };
});

function getFilledToEmailFields (obj) {
    return  Object.entries(obj)
    .filter(([key]) => key.startsWith('to_email'))
    // Filter out blanks.
    .filter(([key, email]) => !!email);
}
