//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapState } from 'vuex';

    export default {
        name: 'StateSelect',

        i18nOptions: { namespaces: ['profile', 'global'] },

        props: {
            'value': {
                type: String,
                required: true,
            },
            isError: {
                type: Boolean,
                default: false,
            },
            defaultOption: {
                type: String,
                default: '',
            },
        },

        data () {
            return {
                fakeSelectText: '&nbsp;',
                focusOn: false,
            };
        },

        computed: {
            ...mapState(['app']),
        },

        methods: {
            handleSelectInput (event) {
                this.fakeSelectText = event.target.selectedOptions[0].text || '&nbsp;';
                this.$emit('input', event.target.value);

                this.focusOn = !!event.target.selectedOptions[0].text;
            },
        },
    };
