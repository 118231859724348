//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapState, mapGetters, mapActions } from 'vuex';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',
        i18nOptions: { namespaces: ['footer', 'links', 'global'] },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            isApplicant () {
                return !!this.profile.referral_code || !!this.$route.query.referralCode;
            },
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

