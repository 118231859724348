//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapState } from 'vuex';

    export default {
        name: 'TheHeader',
        i18nOptions: { namespaces: ['global', 'header'] },

        computed: {
            ...mapState(['profile', 'ui']),

            displayDashboardButton () {
                return ['reg-confirm', 'game', 'rewards', 'rewards-confirm'].includes(this.ui.pageName) && !this.isApplicant;
            },
            displayRegConfirmButton () {
                return ['dashboard'].includes(this.ui.pageName) && !this.isApplicant;
            },

            isApplicant () {
                return !!this.profile.referral_code || !!this.$route.query.referralCode;
            },
        },

    };
