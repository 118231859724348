
import axios from 'axios';
import Vue from 'vue';
import api from '../../api';

const getDefaultState = () => Vue.observable({
    activities: [],
    stats: {},
});


const state = getDefaultState();

const getters = {
    activityHistory (state) {
        return state.activities || [];
    },
    statSignup (state) {
        return state.stats.sign_up || 0;
    },
    statTour (state) {
        return state.stats.tour || 0;
    },
    statPurchase (state) {
        return state.stats.purchase || 0;
    },
};

const mutations = {
    updateActivities (state, newActivities) {
        state.activities = newActivities;
    },
    updateStats (state, newActivities) {
        const newStats = {};
        newActivities.forEach(activity => {
            const stat = newStats[activity.referral_action] || 0;
            newStats[activity.referral_action] = stat + 1;
        });
        state.stats = newStats;
    },
};

const actions = {
    async getReferralActions ({ commit, rootState }) {
        const response = await axios.get(`${api.base}/actions/all/${rootState.profile.id}`);
        const { result } = response.data;
        commit('updateActivities', result);
        commit('updateStats', result);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
