//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import axios from 'axios';
    import { mapState } from 'vuex';
    import Validator from '@components/Validator';
    import { BaseInput, BaseCheckbox } from '@components/form';
    import { viral as validatorGuards } from '../../../lib/validation/client-validators';
    import api from '../api';
    import openModal, { ErrorModal, ViralModal } from '../modals';

    const viralConfig = {"_public":true,"maxFields":1,"displayCAConfirm":true};

    export default {

        components: {
            Validator,
            BaseInput,
            BaseCheckbox,
        },

        props: {
            close: {
                required: true,
                type: Function,
            },
        },

        data () {
            return {
                loading: false,
                numFields: viralConfig.maxFields,
                displayCAConfirm: viralConfig.displayCAConfirm,
                formData: {},
                validatorGuards,
            };
        },

        computed: {
            ...mapState(['profile']),
        },

        i18nOptions: { namespaces: ['viral'] },

        methods: {
            async doTAF () {
                const { formData, profile } = this;

                // Optionally provided for client-side validation, if available.
                formData.selfEmail = profile.email;

                await this.$refs.validator.validate();

                if (this.$refs.validator.isValid) {
                    // This component depends on the UrchinPlugin to provide `$urchin`.
                    this.$urchin.trackEvent('Viral Form', 'Valid Submission');
                }
                else {
                    // Don't continue submitting.
                    return;
                }

                this.loading = true;

                try {
                    await axios.post(`${api.base}/viral`, formData);
                    this.close();
                    this.$router.push('/viral_thanks');
                }
                catch (error) {
                    const { response } = error;

                    if (response && response.data && response.data.errors) {
                        this.$refs.validator.setAllErrors(response.data.errors);
                    }
                }

                this.loading = false;
            },
        },
    };
