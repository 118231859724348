
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    DashboardPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DashboardPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    RegConfirmPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegConfirmPage.vue'
    ),
    RewardsPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RewardsPage.vue'
    ),
    RewardsConfirmPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RewardsConfirmPage.vue'
    ),
    IneligibleStatePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleStatePage.vue'
    );

export default [
    { path: '/', redirect: (to) => {

        const params = new URLSearchParams(window.location.hash.replace('#/', ''));
        let referralCode = params.get('referralCode') || '';

        if (referralCode && referralCode.length > 0) referralCode = referralCode.replace('/', '');


        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }

        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            // Applicants don't need the limiter page -- we just show them the reg confirm message again.
            if (store.state.profile?.applicant) {
                return '/regconfirm';
            }

            // If owner has used up their max referrals (including pending), send them to limiter
            else if (store.state.profile.is_limited) {
                return '/limiter';
            }
            else {
                return '/game';
            }
        }


        // If referral code is present in queryString, route to login page
        else if (referralCode !== '') {
            return '/login?referralCode=' + referralCode;
        }

        // Default routes for returning users (reg cookie) who are not logged-in.
        else if (store.getters['profile/returning']) {
            return '/login';
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/login', component: LoginPage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/landing', component: LandingPage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/dashboard', component: DashboardPage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/register', component: RegisterPage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/limiter', component: LimiterPage, meta: {
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/ineligible_state', component: IneligibleStatePage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/regconfirm', component: RegConfirmPage, meta: {
        public: true,
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/rewards', component: RewardsPage, meta: {
        activeInPhase: ['launched', 'Extension'],
    } },
    { path: '/rewards-confirmed', name: 'rewards-confirmed', component: RewardsConfirmPage, props: true, meta: {
        activeInPhase: ['launched', 'Extension'],
    } },
];
