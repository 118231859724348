//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    export default {
        inheritAttrs: false,
        props: {
            'value': {
                type: String,
                default: '',
                required: true,
            },
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
                default: '',
            },
            name: {
                type: String,
                required: true,
                default: '',
            },
        },
        mounted () {
            this.$nextTick(() => {
                const input = this.$refs[`input_${this._uid}`];
                if (input.attributes.autofocus) {
                    input.focus();
                }
            });
        },
    };
