//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapActions, mapMutations } from 'vuex';
    import Validator from '@components/Validator';
    import { BaseInput, BaseSelect, BaseRecaptcha } from '@components/form';
    import { register as validatorGuards } from '../../../lib/validation/client-validators';
    import store from '../store/index';
    import openModal, { ErrorModal } from '../modals';
    import BasePage from './BasePage';

    export default {
        name: 'RegisterOwnerPage',

        components: {
            Validator,
            BaseInput,
            BaseSelect,
            BaseRecaptcha,
        },

        extends: BasePage,

        props: {
            state: {
                required: true,
                type: String,
            },
            close: {
                required: true,
                type: Function,
            },
        },

        data () {
            return {
                pageName: 'register',
                loading: false,
                recaptchaPubKey: "6LdmAf0SAAAAABgHCfB3ey-HxXCupdgZiuhwN21F",
                brandOptions: [
                    {
                        label: 'Club Wyndham',
                        value: 'club_wyndham',
                    },
                    {
                        label: 'Worldmark by Wyndham',
                        value: 'worldmark',
                    },
                    {
                        label: 'Shell Vacations Club',
                        value: 'shell',
                    },
                    {
                        label: 'Margaritaville Vacation Club',
                        value: 'margaritaville',
                    },
                ],

                // Collect fields before we submit the form.
                tempProfile: {
                    // Set initial/default values for these fields.
                    brand: '',
                    primary_opt_in: false, // pre-check the opt-in
                    member_id: undefined,

                    // If there's already profile data in the Vuex store, such as from the
                    // email field on the login page, or other data collected before we got
                    // to this page, merge that in as well, so the fields are prefilled.
                    ...store.state.profile,
                },

                validatorGuards,
                isNameValidationError: false,
            };
        },

        i18nOptions: { namespaces: ['registration', 'profile', 'intro'] },

        created () {
            this.logOut();
        },

        methods: {
            ...mapActions({
                register: 'profile/register',
                logOut: 'profile/logOut',
            }),

            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            async doRegister (e) {
                const { tempProfile } = this;
                const { validator } = this.$refs;

                if (tempProfile.state && !tempProfile.country) {
                    const state = tempProfile.state.split('-');

                    if (state.length > 1) {
                        tempProfile.country = state[0];
                    }
                }
                if (!tempProfile.country) tempProfile.country = 'US';

                if (this.state) {
                    tempProfile.state = this.state;
                }

                tempProfile.x_channel = this.app.channel || undefined;
                tempProfile.affiliate_id = this.app.affiliateId || undefined;
                tempProfile.locale = this.app.locale || undefined;
                // Allows us to control client-side recaptcha validation.
                tempProfile.isAutomatedTest = this.app.isAutomatedTest;

                tempProfile.referral_code = this.$route.query.referralCode || false;

                // TODO: How to test this?
                try {
                    tempProfile['g-recaptcha-response'] = grecaptcha.getResponse();
                }
                catch (err) { console.log('getting recaptcha response', err, err.message); }

                await validator.validate();
                this.isNameValidationError = this.checkForNameErrors(validator);

                if (!validator.isValid) {
                    return;
                }

                // This component depends on the UrchinPlugin to provide `$urchin`.
                this.$urchin.trackEvent('Register Form', 'Valid Submission');

                this.loading = true;

                try {
                    await this.register({ tempProfile });

                    this.loading = false;
                    this.$router.replace('/regconfirm');

                    this.$parent.closeModal();
                }
                catch (error) {
                    this.loading = false;

                    const { response } = error;

                    if (response && response.status === 409) {
                        this.updateProfile(tempProfile);

                        // TODO hide this from showing up in the url?
                        this.close();
                        this.$router.replace('/dup_account');
                    }
                    else if (response && response.data && response.data.errors) {

                        validator.setAllErrors(response.data.errors);

                        if (validator.isAnyErrorMessageLike(/minAge/i)) {
                            this.$router.replace('/ineligible_age');
                            return;
                        }

                        // Refresh reCAPTCHA.
                        this.$refs.recaptcha.reset();
                    }
                    else {
                        openModal(ErrorModal);
                    }
                }
            },
            checkForNameErrors (validator) {
                return validator.allErrors.length < 3 && this.errorsAreNotNull(validator) && this.errorsIncludeANameField(validator);
            },

            errorsAreNotNull (validator) {
                return !validator.allErrors.some(err => err.error === 'NULL');
            },

            errorsIncludeANameField (validator) {
                return validator.allErrors.some(err => err.name.includes('name'));
            },

            // Need to use a method here to close the modal after navigating.
            navigateToLogin () {
                this.$router.push('/login');
                this.close();
            },
        },
    };
