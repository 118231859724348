//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import BaseModal from './BaseModal';

    export default {
        name: 'OwnerVideoModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'owner_video',
            };
        },
        i18nOptions: { namespaces: ['modal_content', 'videos'] },
    };
