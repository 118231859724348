
import { mapState } from 'vuex';

import { trackEvent } from '../plugins/UrchinPlugin';
import store from '../store';
import i18n from '../i18n';
import router from '../router';

const focusableSelector = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'iframe',
    '[tabindex]',
    '[contentEditable=true]',
].join(',');

export default {
    name: 'BaseModal',

    data () {
        return {
            modalName: '',
            // Set to false to disable the escape key closing the modal. Some modals may require
            // some process to be completed and have a special close handler for the CTA, etc.
            // A fully accessible modal should allow this, so only disable this when necessary,
            // and only in the data of the specific modal(s) that you want it disabled on.
            escapeKeyClosesModal: true,
        };
    },

    // Modals are started up as a completely separate Vue app; we add some
    // properties here so they have the same functionality as the main app.
    i18n,
    router,
    store,

    computed: {
        // Map state very commonly used by modals, for convenience.
        ...mapState([
            'app',
            'profile',
        ]),

        focusableElements () {
            return this.$el.querySelectorAll(focusableSelector);
        },
    },

    mounted () {
        document.body.setAttribute('data-e2e-modal', this.modalName);

        store.commit('ui/modalOpen', this);

        trackEvent('Modal', 'Open', this.modalName);

        this.$el.addEventListener('keydown', this.keydownListener);
        this.$nextTick(() => {
            this.focusableElements[0].focus();
        });
    },

    beforeDestroy () {
        document.body.removeAttribute('data-e2e-modal');

        store.commit('ui/modalClose');

        trackEvent('Modal', 'Close', this.modalName);

        this.$el.removeEventListener('keydown', this.keydownListener);
    },

    methods: {
        // `arg` in either of these methods will end up resolved as the
        // value of the openModal promise
        cancelModal (arg) {
            this.$emit('cancel-modal', arg);
        },

        closeModal (arg) {
            this.$emit('close-modal', arg);
        },

        keydownListener (event) {
            // Note that all component methods automatically have their `this` context
            // bound to the Vue instance. This is why we do not need to do it manually
            // when registering this method as an event listener above.
            // https://vuejs.org/v2/api/#methods
            const firstFocusableElement = this.focusableElements[0],
                lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];

            switch (event.code) {
                case ('Tab'):
                    if (event.shiftKey) {
                        if (document.activeElement === firstFocusableElement) {
                            event.preventDefault();
                            lastFocusableElement.focus();
                        }
                    }
                    else {
                        if (document.activeElement === lastFocusableElement) {
                            event.preventDefault();
                            firstFocusableElement.focus();
                        }
                    }
                    break;
                case ('Escape'):
                    if (this.escapeKeyClosesModal) {
                        this.closeModal();
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
